<template>
  <div class="accountFContainer">
    <a-form-model :model="accountForm" labelAlign="left" ref="accountForm" v-bind="layout">
      <a-form-model-item label="注册邮箱">
        <a-input v-model.trim="accountForm.loginId" disabled />
      </a-form-model-item>
      <a-form-model-item label="角色">
        <a-input v-model.trim="accountForm.rolesName" disabled />
      </a-form-model-item>
      <a-form-model-item
        label="账号名称"
        prop="name"
        :rules="{
          required: true,
          message: '请输入账号名称',
          trigger: 'blur'
        }"
      >
        <a-input placeholder="请输入账号名称" v-model.trim="accountForm.name" />
      </a-form-model-item>
      <a-form-model-item
        label="公司名称"
        prop="company"
        :rules="{
          required: true,
          message: '请输入公司名称',
          trigger: 'blur'
        }"
      >
        <a-input placeholder="请输入公司名称" v-model.trim="accountForm.company" />
      </a-form-model-item>
      <a-form-model-item
        label="联系人"
        prop="contact"
        :rules="{
          required: true,
          message: '请输入联系人',
          trigger: 'blur'
        }"
      >
        <a-input placeholder="请输入联系人" v-model.trim="accountForm.contact" />
      </a-form-model-item>
      <a-form-model-item
        label="手机号码"
        prop="mobile"
        :rules="{
          required: true,
          message: '请输入手机号码',
          trigger: 'blur'
        }"
      >
        <a-input placeholder="请输入手机号码" v-model.trim="accountForm.mobile" />
      </a-form-model-item>
      <!-- <a-form-model-item
        label="主营应用"
      >
        <a-input placeholder="请输入主营应用" v-model.trim="accountForm.mainApp" />
      </a-form-model-item> -->
      <a-form-model-item class="editBtn" label=" " style="text-align: right">
        <a-button type="primary" class="primary" @click="handleSubmit">修改</a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>
<script>
import { getUserInfo, updateAccountInfo } from '@/api/system'
export default {
  name: 'AccountForm',
  data () {
    return {
      accountForm: {},
      layout: {
        labelCol: { span: 5 },
        wrapperCol: { span: 16 }
      }
    }
  },
  created () {
    this.getUserInfoFn()
  },
  methods: {
    // 获取基本信息
    async getUserInfoFn (data) {
      const res = await getUserInfo(data)
      if (res.code === 200 && res.data) {
        const { username, loginId, id, roles, company, contact, mobile, mainApp, roleName } = res.data
        this.accountForm = {
          ...this.accountForm,
          id,
          username,
          name: username,
          loginId,
          roles,
          rolesName: roleName,
          // rolesName: roles.length !== 0 && this.formatRolesName(roles[0]),
          company,
          contact,
          mobile,
          mainApp
        }
      }
    },
    // 格式化 角色名称
    formatRolesName (key) {
      switch (key) {
        case 'SUPER_ADMIN':
          return '超级管理员'
        case 'ADMIN':
          return '管理员'
        case 'OPERATOR':
          return '运营'
        case 'VIEWER':
          return '报表'
        case 'AUDITOR':
          return '审核员'
        case 'BUSINESS':
          return '商务'
        case 'MEDIA':
          return '媒体'
        case 'BUSINESS_DIRECTOR':
          return '商务总监'
        case 'OPERATOR_DIRECTOR':
          return '运营总监'
        case 'TECHNOLOGY':
          return '技术'
        case 'FINANCIAL':
          return '财务'
        case 'MANAGEMENT':
          return '管理层'
        case 'ADVANCED_OPERATOR':
          return '高级运营'
        case 'ADVERTISERS':
          return '广告主'
        case 'AGENT':
          return '代理商'
        default:
          return '未知'
      }
    },
    // 修改信息
    async updateAccountInfoFn (data) {
      const res = await updateAccountInfo(data)
      if (res.code === 200) {
        this.$message.success('修改成功')
        this.getUserInfoFn()
      }
    },
    handleSubmit () {
      this.$refs.accountForm.validate((validate) => {
        if (!validate) return false
        this.updateAccountInfoFn(this.accountForm)
      })
    }
  }
}
</script>
<style lang="less" scoped>
.accountFContainer {
  width: 500px;
}
.editBtn {
  ::v-deep .ant-form-item-label {
    label {
      display: none;
    }
  }
}
::v-deep.ant-form-item {
  margin-bottom: 20px;
}
</style>
